<template>
    <div id="app">
        <v-app id="inspire" :style="{background: '#FFC324'}">
            
            <v-container>
                <!-- <v-list flat elevation="5" v-show="!norecord">
                    <v-subheader>
                        <v-img 
                            :src="'../images/'+$route.params.id+'.png'"
                            class="white--text align-end"
                            height="200px"
                        />
                    </v-subheader> 
                    <br><br><br>
                    <div class="text-h6 ma-3">
                        ประกันของฉัน 
                        <v-progress-circular
                            v-show="loading"
                            indeterminate
                            color="amber"
                        ></v-progress-circular>
                    </div>
                    <v-list-item-group
                        color="primary"
                    >
                        <v-list-item
                            v-for="(item) in items"
                            :key="item.ins1"
                            :to="'/insure/'+$route.params.id+'/'+item.ins1"
                        >
                            <v-list-item-content>
                                <v-list-item-title v-text="item.ins4"></v-list-item-title>
                                <v-list-item-subtitle>
                                    <span v-if="item.ins9 == '1'">ทะเบียนรถ {{item.ins11}} {{item.ins12}}<br></span>
                                    <span>บริษัท {{item.ins10}}<br></span>
                                </v-list-item-subtitle>
                                <v-divider></v-divider>
                            </v-list-item-content>
                            
                        </v-list-item>
                        
                    </v-list-item-group>
                </v-list> -->
                
                <v-card
                    class="mx-auto"
                    max-width="500"
                >

                    <v-toolbar
                        style="background-color:#273b81"
                        dark
                    >
                        <v-toolbar-title><v-icon>mdi-file-document-outline</v-icon>&nbsp;{{title}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn v-show="this.$router.currentRoute.path.startsWith('/car/')" icon to="/history">
                            <v-icon>mdi-wrench</v-icon>
                        </v-btn>
                        <v-btn v-show="manageButton" icon @click="showAdd">
                            <v-icon>mdi-text-box-plus-outline</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-progress-circular
                        v-show="loading"
                        indeterminate
                        color="amber"
                    ></v-progress-circular>

                    <v-card
                        elevation="5"
                        v-show="norecord"
                    >
                        <v-card-title
                        class="text-h6"
                        >ไม่พบข้อมูล</v-card-title>

                        <v-expansion-panels>
                            <v-expansion-panel>
                            <v-expansion-panel-header>
                                <div class="ml-n2 text-h6">
                                    <v-icon>mdi-phone-outline</v-icon>
                                    สนใจซื้อประกัน
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <!-- <v-btn
                                    href="https://lin.ee/8AdoXfl" 
                                    color="#FFC324"
                                    text
                                    class="mt-3"
                                    target="_blank"
                                ><v-icon>mdi-chat-processing</v-icon> &nbsp;<font color="black">Chat กับเจ้าหน้าที่</font></v-btn>
                                <br> -->
                                <v-btn
                                    href="tel:021146464" 
                                    color="#FFC324"
                                    text
                                    class="mt-2"
                                ><v-icon>mdi-phone</v-icon> &nbsp;<font color="black">021146464</font></v-btn>
                            </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card>

                    <v-list two-line v-show="!norecord">
                        <v-list-item-group>
                            <template v-for="(item,index) in items">
                                <v-list-item :key="item.ins1" :style="getColor(index)">
                                    <v-avatar @click="goto('/insure/'+item.ins9+'/'+item.ins1)" tile>
                                        <v-img v-if="item.ins9 == '1' && !item.v7" :src="'../images/car.jpg'" contain></v-img>
                                        <v-img v-if="item.ins9 == '1' && item.v7" :src="'../images/cars/'+item.v7" contain></v-img>
                                        <!-- <v-img v-if="item.ins9 != '1'" :src="'../images/'+item.ins9+'.png'" contain></v-img> -->
                                        <v-icon v-if="item.ins9 == '2'">mdi-plus-circle-outline</v-icon>
                                        <v-icon v-if="item.ins9 == '3'">mdi-text-box-multiple-outline</v-icon>
                                    </v-avatar>
                                    &nbsp;
                                    <v-list-item-content @click="goto('/insure/'+item.ins9+'/'+item.ins1)">
                                        <v-list-item-title v-if="item.ins9 == '1'">{{item.ins10}} ({{item.ins7.substring(0,4)}})</v-list-item-title>
                                        <v-list-item-title v-if="item.ins9 != '1'">{{item.ins4}} ({{item.ins7.substring(0,4)}})</v-list-item-title>
                                        <v-list-item-subtitle>
                                            <span v-if="item.ins9 == '1'" class="mt-n3">{{item.ins4}} {{item.ins5}}</span>
                                            <span v-if="item.ins9 != '1'" class="mt-n3">{{item.ins10}}</span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action v-show="manageButton">
                                        <v-btn icon @click="showEdit(item)">
                                            <v-icon>mdi-square-edit-outline</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                    <v-list-item-action v-show="manageButton">
                                        <v-btn icon @click="deleteDialog(item)">
                                            <v-icon>mdi-trash-can-outline</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>

                    <v-btn
                        large
                        @click="backPage()"
                        block
                    >
                        ย้อนกลับ
                    </v-btn>
                    
                </v-card>
                
            </v-container>

            <v-dialog v-model="dialogManage" persistent>
                <v-card>
                <v-card-title class="text">จัดการกรมธรรม์</v-card-title>
                <v-card-text>
                    <v-form v-model="verify" ref="form" lazy-validation>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field label="บริษัทประกัน" :rules="rulesBlank" v-model="itemEdit.ins10"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field label="หมายเลขกรมธรรม์" :rules="rulesBlank" v-model="itemEdit.ins3"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field label="ชื่อกรมธรรม์" :rules="rulesBlank" v-model="itemEdit.ins4"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-menu
                                    v-model="pickerIS"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="itemEdit.ins6" label="วันที่เริ่มกรมธรรม์" :rules="rulesBlank" prepend-icon="mdi-calendar"
                                        readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="insstart" 
                                        @input="closeIS"
                                        year-icon="mdi-calendar-blank"
                                        locale="th"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-menu
                                    v-model="pickerIE"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="itemEdit.ins7" label="วันที่สิ้นสุดกรมธรรม์" :rules="rulesBlank" prepend-icon="mdi-calendar"
                                        readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="insend" 
                                        @input="closeIE"
                                        year-icon="mdi-calendar-blank"
                                        locale="th"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <!-- <v-col cols="12" md="12">
                                <v-select :items="cars" :item-text="cars => cars.brand+' '+cars.model+' '+cars.carregis+' '+cars.carprovince"
                                    item-value="vid" :rules="rulesBlankCar" v-model="itemEdit.vid" label="รายละเอียดรถ"></v-select>
                            </v-col> -->
                            <v-col cols="12" md="6">
                                <v-textarea
                                    v-model="itemEdit.ins5"
                                    label="รายละเอียดกรมธรรม์" :rules="rulesBlank"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field label="Link ไฟล์กรมธรรม์ (URL)" v-model="itemEdit.ins8"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="dialogManage = false">ยกเลิก</v-btn>
                    <v-btn color="primary" depressed @click="manage()">ตกลง</v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDel" persistent >
                <v-card>
                    <v-card-title class="text">คุณต้องการลบ {{delParam}} ใช่หรือไม่</v-card-title>
                    <v-card-actions>
                        <v-btn color="primary darken-1" @click="dialogDel = false">ยกเลิก</v-btn>
                        <v-btn color="error darken-1" @click="deleteInsure">ใช่</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="alert" persistent >
                <v-card>
                    <v-card-title class="text">{{message}}</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="alert = false">ปิด</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


        </v-app>
    </div>
</template>

<script>

    export default {
        name: 'InsureList',
        data: function() {
                return {
                    manageButton: false,
                    valid: true,
                    alert: false,
                    dialogDel: false,
                    dialogManage: false,
                    delParam: '',
                    message: '',
                    color: '',
                    pickerIS: false,
                    insstart: null,
                    pickerIE: false,
                    insend: null,
                    items: [],
                    itemEdit: {},
                    norecord: false,
                    loading: true,
                    delID: '',
                    verify: true,
                    rulesBlank: [
                        v => !!v || 'Required',
                    ],
                    title: 'ประกันของฉัน',
            };
        },

        mounted () {
            window.scrollTo(0, 0)
        },

        beforeMount() {
            this.getAllInsure();
        },

        methods: {
            
            getAllInsure:async function(){
                var app = this;
                let formData = new FormData();
                if(this.$router.currentRoute.path.startsWith('/car/')){
                    formData.append('ins9', '1');
                    formData.append('ins11', this.$route.params.cid);
                } else {
                    formData.append('ins9', this.$route.params.id);
                }
                formData.append('mode', 'getInsure');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.loading = false
                        app.items = response.data
                        if(app.items.length){
                            if(app.items[0].ins9 == '1') app.title = app.items[0].v5 + " " + app.items[0].v6;
                            if(app.items[0].ins9 == '2') app.title = "ประกันสุขภาพ";
                            if(app.items[0].ins9 == '3') app.title = "ประกันอื่นๆ";
                        }else {
                            app.norecord = true
                        } 
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            getColor: function(index){
                if(index%2 == 0){
                    return { 'background-color':''};
                } else {
                    return { 'background-color':'#E7E7E7'};
                }
            },

            goto: function(param){
                this.$router.push(param)
            },

            closeIS (){
                this.pickerIS = false;
                this.itemEdit.ins6 = this.insstart ? this.$moment(this.insstart).add(543, 'year').format('YYYY-MM-DD') : '';
            },

            closeIE (){
                this.pickerIE = false;
                this.itemEdit.ins7 = this.insend ? this.$moment(this.insend).add(543, 'year').format('YYYY-MM-DD') : '';
            },

            showAdd: async function () {
                if(this.items.length < 5){
                    var app = this;
                    app.dialogManage = true
                    app.itemEdit = {}
                    if (this.$refs.form) {
                        this.$refs.form.resetValidation()
                    }
                } else {
                    this.alert = true;
                    this.message = 'ท่านสามารถเพิ่มกรมธรรม์ได้ 5 เล่มเท่านั้น'
                }
                
            },

            showEdit: async function (item) {
                var app = this;
                app.dialogManage = true
                app.itemEdit = Object.assign({}, item)
                if (this.$refs.form) {
                    this.$refs.form.resetValidation()
                }
            },

            manage: async function () {
                var app = this;
                if(!this.$refs.form.validate()) return;
                var formData = new FormData();
                if(this.$router.currentRoute.path.startsWith('/car/')){
                    formData.append('ins9', '1');
                    formData.append('ins11', this.$route.params.cid);
                } else {
                    formData.append('ins9', this.$route.params.id);
                }
                if(app.itemEdit.ins1) formData.append("ins1", app.itemEdit.ins1)
                if(app.itemEdit.ins3) formData.append("ins3", app.itemEdit.ins3)
                if(app.itemEdit.ins4) formData.append("ins4", app.itemEdit.ins4)
                if(app.itemEdit.ins6) formData.append("ins6", app.itemEdit.ins6)
                if(app.itemEdit.ins7) formData.append("ins7", app.itemEdit.ins7)
                if(app.itemEdit.ins10) formData.append("ins10", app.itemEdit.ins10)
                if(app.itemEdit.ins5) formData.append("ins5", app.itemEdit.ins5)
                if(app.itemEdit.ins8) formData.append("ins8", app.itemEdit.ins8)
                formData.append("mode", 'manageInsure')
                // for (var value of formData.values()) {
                //     console.log(value);
                // }
                await this.$axios.post(this.$hostUrl+'get/', formData,
                {
                    headers: {'Content-Type': 'multipart/form-data'}
                })
                .then(function (response) {
                    if(!response.data){
                        app.message = response
                        app.alert = true
                    }else{
                        app.dialogManage = false
                        app.alert = true;
                        app.message = response.data;
                        app.getAllInsure();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    app.dialogManage = false
                });
            },

            deleteDialog: function(obj){
                this.delParam = obj.ins4;
                this.delID = obj.ins1;
                this.dialogDel = true;
            },

            deleteInsure:async function(){
                this.dialogDel = false;
                var app = this;
                let formData = new FormData();
                if(app.delID) formData.append('ins1', app.delID);
                formData.append('status', 'D');
                formData.append('mode', 'manageInsure');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.alert = true;
                        app.message = response.data;
                        app.delID = '';
                        app.getAllInsure();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            backPage: function(){
                history.back();
            }

        },
    }

</script>