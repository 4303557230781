<template>
    <div id="app">
        <v-app id="inspire" :style="{background: '#FFC324'}">

            <template>

                <v-row>
                    <v-col md="4" offset-md="4" sm="6" offset-sm="3" >
                        <v-card>
                            <v-carousel 
                                cycle
                                height="250"
                            >
                                <template v-for="(item,index) in items">
                                    <v-carousel-item :key="index">
                                        <v-sheet
                                            height="100%"
                                            tile
                                        >
                                            <v-row
                                                class="fill-height"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-img :src="$image+'images/banner/banner_'+item.bid+'.jpg'"  @click="goto(item.blink)" />
                                            </v-row>
                                        </v-sheet>
                                    </v-carousel-item>
                                </template>

                                <!-- <v-carousel-item href="http://www.google.com" target="_blank">
                                    <v-sheet
                                        height="100%"
                                        tile
                                    >
                                        <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-img src="images/tax.jpeg" />
                                        </v-row>
                                    </v-sheet>
                                </v-carousel-item> -->
                            </v-carousel>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            
            <v-container>
                <v-row>
                    <v-col v-if="agent.link1" cols="6" md="4" lg="3" class="mr-n2">
                        <v-card
                            :href=agent.link1
                            elevation="5"
                        >
                            <div class="pa-4 text-h6" align="center">
                                <v-icon class="mt-n1">mdi-bullhorn-outline</v-icon>
                                แจ้งงาน
                            </div>
                        </v-card>
                    </v-col>
                    <v-col v-if="agent.link2" cols="6" md="4" lg="3" class="mr-n2">
                        <v-card
                            :href=agent.link2
                            elevation="5"
                        >
                            <div class="pa-4 text-h6" align="center">
                                <v-icon class="mt-n1">mdi-format-list-bulleted</v-icon>
                                ผลงาน
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <template v-for="(menu,index) in menuList">

                        <v-col :key="index" cols="6" md="4" lg="3" class="mr-n2">
                            <v-card
                                :to="menu.link"
                                elevation="5"
                            >
                                <div :class="'py-4 '+menu.size " align="center">
                                    <v-icon x-large class="pb-2">{{ menu.icon }}</v-icon>
                                    <br>
                                    {{menu.title}}
                                </div>
                            </v-card>
                        </v-col>

                    </template>

                    <v-col cols="12" class="mb-n2 pr-5">
                        <v-card
                            elevation="5"
                        >
                        <v-expansion-panels>
                            <v-expansion-panel>
                            <v-expansion-panel-header>
                                <div class="ml-n2 text-h6" align="center">
                                    <v-icon>mdi-phone-outline</v-icon>
                                    ติดต่อเจ้าหน้าที่
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <!-- <v-btn
                                    href="https://lin.ee/8AdoXfl" 
                                    color="#FFC324"
                                    text
                                    class="mt-3"
                                    target="_blank"
                                ><v-icon>mdi-chat-processing</v-icon> &nbsp;<font color="black">Chat กับเจ้าหน้าที่</font></v-btn>
                                <br> -->
                                <v-btn
                                    href="tel:021146464" 
                                    color="#FFC324"
                                    text
                                    class="mt-2"
                                ><v-icon>mdi-phone</v-icon> &nbsp;<font color="black">021146464</font></v-btn>
                            </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        </v-card>
                    </v-col>
                    
                    <!-- <v-col cols="12" class="mb-n2">
                        <v-card
                            to="/car/"
                            elevation="5"
                        >
                            <div class="pa-4 text-h6">
                                <v-icon>mdi-car-side</v-icon>
                                รถของฉัน
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" class="mb-n2">
                        <v-card
                            to="/history"
                            elevation="5"
                        >
                            <div class="pa-4 text-h6">
                                <v-icon>mdi-wrench</v-icon>
                                ประวัติการใช้บริการ
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" class="mb-n2">
                        <v-card
                            to="/place"
                            elevation="5"
                        >
                            <div class="pa-4 text-h6">
                                <v-icon>mdi-map-marker-outline</v-icon>
                                ค้นหาสถานที่
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" class="mb-n2">
                        <v-card
                            to="/tax"
                            elevation="5"
                        >
                            <div class="pa-4 text-h6">
                                <v-icon>mdi-calculator</v-icon>
                                คำนวณภาษีรถ
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" class="mb-n2">
                        <v-card
                            to="/emergency"
                            elevation="5"
                        >
                            <div class="pa-4 text-h6">
                                <v-icon>mdi-car-emergency</v-icon>
                                เหตุฉุกเฉิน
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" class="mb-n2">
                        <v-card
                            to="/location"
                            elevation="5"
                        >
                            <div class="pa-4 text-h6">
                                <v-icon>mdi-store-marker</v-icon>
                                สถานที่สำคัญ
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" class="mb-n2">
                        <v-card
                            to="/application"
                            elevation="5"
                        >
                            <div class="pa-4 text-h6">
                                <v-icon>mdi-cellphone-information</v-icon>
                                APP แนะนำ
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" class="mb-n2">
                        <v-card
                            to="/insure"
                            elevation="5"
                        >
                            <div class="pa-4 text-h6">
                                <v-icon>mdi-file-document-outline</v-icon>
                                ประกันของฉัน
                            </div>
                        </v-card>
                    </v-col> -->
                    
                </v-row>
            </v-container>

        </v-app>
    </div>
</template>

<script>
    export default {
        name: 'HomeMenu',
        data: function() {
        return {
                valid: true,
                alert: false,
                message: '',
                color: '',
                items: [],
                colors: [
                    'primary',
                    'secondary',
                    'yellow darken-2',
                    'red',
                    'orange',
                ],
                agent:{},
                menuList: [
                    { link: '/car/', icon: 'mdi-car-side', title: 'รถของฉัน', size: 'text-h6'},
                    { link: '/history', icon: 'mdi-wrench', title: 'ประวัติการใช้บริการ', size: 'text-subtitle-1'},
                    { link: '/insure', icon: 'mdi-file-document-outline', title: 'ประกันของฉัน', size: 'text-h6'},
                    // { link: '/cargas/', icon: 'mdi-gas-station', title: 'รถติดแก๊ส', size: 'text-h6'},
                    { link: '/place', icon: 'mdi-map-marker-outline', title: 'ค้นหาสถานที่', size: 'text-h6'},
                    { link: '/tax', icon: 'mdi-calculator', title: 'คำนวณภาษีรถ', size: 'text-h6'},
                    { link: '/emergency', icon: 'mdi-car-emergency', title: 'เหตุฉุกเฉิน', size: 'text-h6'},
                    // { link: '/location', icon: 'mdi-store-marker', title: 'สถานที่สำคัญ', size: 'text-h6'},
                    // { link: '/application', icon: 'mdi-cellphone-information', title: 'APP แนะนำ', size: 'text-h6'},
                    
                ]
            };
        },

        mounted () {
            window.scrollTo(0, 0)
        },

        async beforeMount(){
            this.getCarusels();
            var app = this;
            let formData = new FormData();
            formData.append('mode', 'getAgentLink');
            await this.$axios.post(this.$hostUrl+'get/', formData)
            .then(function (response) {
                if(!response.data){
                    alert("ERROR Please contact admin")
                }else{
                    if(response.data.length > 0) app.agent = response.data[0];
                }
            })
            .catch(function (error) {
                console.log(error);
            });
            
        },

        methods: {

            getCarusels:async function(){
                var app = this;
                let formData = new FormData();
                formData.append('mode', 'getCarousels');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.items = response.data
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            goto: function(param){
                if(param.startsWith("http")){
                    window.open(param)
                } else {
                    this.$router.push(param)
                }
                
            },

        },
    }

</script>